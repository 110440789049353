import React from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { SignUpButton } from "@clerk/clerk-react";

import "./AboutUs.css";

function AboutUs() {
  return (
    <div
      id="about-us-content-wrapper"
      style={{
        textAlign: "left",
        minHeight: "100vh",
      }}
    >
      <div style={{textAlign: 'center'}}>
        <h3>About Us</h3>
        <h1>
          <b>We know DevSecOps</b>
        </h1>
      </div>

      <Row
        style={{
          marginTop: '150px',
          marginLeft: 'auto',
          marginRight: 'auto',
          borderTop: '3px solid black'
        }}
      >
        <Row>
          <Col>
            <h2 style={{fontWeight: 'bold'}}>Our Mission</h2>
          </Col>
          <Col lg={9} style={{
            paddingTop: '60px',
            fontSize: '40px'
          }}>
            Everyday attackers are discovering new vulnerabilities and exploiting them. We're building tools
            to make cybersecurity easy, simple, and achievable for any size company.
          </Col>
        </Row>
      </Row>

      <Row
        style={{
          marginTop: '150px',
          marginLeft: 'auto',
          marginRight: 'auto',
          borderTop: '3px solid black'
        }}
      >
        <Row>
          <Col>
            <h2 style={{fontWeight: 'bold'}}>Our Team</h2>
          </Col>
          <Col lg={9} style={{
            paddingTop: '60px',
            fontSize: '40px'
          }}>
            Together, we hold decades of experience in the SaaS, Security, Manufacturing, Government, and Pharmaceutical industries.
          </Col>
        </Row>
      </Row>

      <Row
        style={{
          marginTop: '150px',
          marginLeft: 'auto',
          marginRight: 'auto',
          borderTop: '3px solid black'
        }}
      >
        <Row>
          <h2 style={{fontWeight: 'bold'}}>Our Values</h2>
        </Row>

        <Row style={{
          justifyContent: 'right',
          marginTop: '25px',
        }}>
          <Col md="auto">
            <Card
              style={{
                border: '1',
                borderColor: 'black',
                backgroundColor: 'white',
                maxWidth: '250px',
                minHeight: '270px',
                fontSize: '22px',
                marginTop: '25px',
              }}
            >
              <Card.Body>
                <Card.Title style={{fontWeight: 'bold'}}>
                  People
                </Card.Title>
                <Card.Text>
                  The forefront of our business - both those we serve and those we employ.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md="auto">
            <Card
              style={{
                border: '1',
                borderColor: 'black',
                backgroundColor: 'white',
                maxWidth: '250px',
                minHeight: '270px',
                fontSize: '20px',
                marginTop: '25px',
              }}
            >
              <Card.Body>
                <Card.Title style={{fontWeight: 'bold'}}>
                  Ethics
                </Card.Title>
                <Card.Text>
                  Defense from attacks is mission-critical. We leave no room for bad actors to operate.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md="auto">
            <Card
              style={{
                border: '1',
                borderColor: 'black',
                backgroundColor: 'white',
                maxWidth: '250px',
                minHeight: '270px',
                fontSize: '22px',
                marginTop: '25px',
              }}
            >
              <Card.Body>
                <Card.Title style={{fontWeight: 'bold'}}>
                  Trust
                </Card.Title>
                <Card.Text>
                  Peace of mind that your assets are monitored, providing utmost security for high-expectation clientele.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

        </Row>
      </Row>

      <Row
      style={{
        marginTop: '200px'
      }}
      >
        <SignUpButton>
          <Button
            style={{
              background: 'black',
              borderColor: 'black',
              maxWidth: '400px',
              paddingTop: '20px',
              paddingBottom: '20px',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginBottom: '60px',
            }}
          >
            Join us today for free →
          </Button>
        </SignUpButton>
      </Row>

    </div>
  );
}

export default AboutUs;
