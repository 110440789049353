// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* NavBarDropdownButton.css */

.mainContainer {
  display: flex;
  min-width: 200px;
  height: 70px;
  align-items: center;
  padding-left: 5px;
}

.iconContainer {
  display: flex;
  border: 1px solid lightgray;
  background-color: white;
  border-radius: 10px;
  height: 60px;
  width: 60px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.iconContainerIcon {
  width: 20px;
  height: 20px;
  color: #9a5df5;
}

.textContainer {
  color: black;
}
`, "",{"version":3,"sources":["webpack://./src/components/NavBarDropdownButton.css"],"names":[],"mappings":"AAAA,6BAA6B;;AAE7B;EACE,aAAa;EACb,gBAAgB;EAChB,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,YAAY;AACd","sourcesContent":["/* NavBarDropdownButton.css */\n\n.mainContainer {\n  display: flex;\n  min-width: 200px;\n  height: 70px;\n  align-items: center;\n  padding-left: 5px;\n}\n\n.iconContainer {\n  display: flex;\n  border: 1px solid lightgray;\n  background-color: white;\n  border-radius: 10px;\n  height: 60px;\n  width: 60px;\n  align-items: center;\n  justify-content: center;\n  margin-right: 10px;\n}\n\n.iconContainerIcon {\n  width: 20px;\n  height: 20px;\n  color: #9a5df5;\n}\n\n.textContainer {\n  color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
