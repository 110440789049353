import { useAuth } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import { revokeApiKey, createNewApiKey } from "../utils/apiRequests";

// React Components
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { getUsersApiKeys } from "../utils/apiRequests";
import DateTimeFormatter from "./DateTimeFormatter";
import ApiKeyTruncated from "./ApiKeyTruncated";

// CSS imports
import "./SettingsApiKey.css";


// ---
function SettingsApiKeys() {
  const [keys, setKeys] = useState([])
  const { getToken } = useAuth()

  // handle revoke button
  async function handleRevokeApiKeyButtonClick(apiKeyId) {
    const token = await getToken({ template: 'protean-default' })
    await revokeApiKey(token, apiKeyId)
    await getAPIKeys()
  }

  // create new api key
  async function handleCreateNewApiKeyButtonClick() {
    const token = await getToken({ template: 'protean-default' })
    await createNewApiKey(token)
    await getAPIKeys()
  }

  // get data
  async function getAPIKeys() {
    const token = await getToken({ template: 'protean-default' })
    const { data } = await getUsersApiKeys(token)
    if (data) {
      setKeys([...data])
    }
  }

  // Inital component mount fetch projects data
  useEffect(() => {
    getAPIKeys()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container className="settings-api-keys">
      <Row>
        <Col>
          <h3>API Keys</h3>
        </Col>
        <Col>
          <Button
            variant="outline-dark"
            className='create-new-button rounded-1'
            onClick={() => handleCreateNewApiKeyButtonClick()}
          >
            New ApiKey
          </Button>
        </Col>
      </Row>

      <Row>
      <Table className="api-keys-table">
        <thead>
        <tr>
          <th>Key</th>
          <th>ID</th>
          <th>Created</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {
          keys ?
            keys.map(k => {
              return <tr key={k.api_key}>
                <td><ApiKeyTruncated apiKey={k.api_key} /></td>
                <td><pre>{k.id}</pre></td>
                <td><pre><DateTimeFormatter dateTimeString={k.created_at} /></pre></td>
                <td>
                  <Button
                    variant='danger'
                    size="sm"
                    onClick={() => handleRevokeApiKeyButtonClick(k.id)}
                  >
                    Revoke
                  </Button>
                </td>
              </tr>
            })
          :
            <tr>
              <td>
                <p>No API Keys</p>
              </td>
              <td></td>
              <td></td>
            </tr>
        }
        </tbody>
      </Table>
      </Row>
    </Container>
  )
}

export default SettingsApiKeys