// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard {
  min-height: calc(90vh - 20px);
  padding-top: 20px;
  padding-bottom: 30px;
}

/* .dashboard .dashboard-row { */
  /* margin-bottom: 30px; */
/* } */

.dashboard tbody th {
  font-weight: 100;
}

.scan-id-link {
  text-decoration: none;
  color: black;
  border-bottom: 1px black dotted;
}

.scan-id-link:hover {
  text-decoration: none;
  color: black;
  border-bottom: 1px black solid;
}`, "",{"version":3,"sources":["webpack://./src/pages/DashboardScans.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA,gCAAgC;EAC9B,yBAAyB;AAC3B,MAAM;;AAEN;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,+BAA+B;AACjC;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,8BAA8B;AAChC","sourcesContent":[".dashboard {\n  min-height: calc(90vh - 20px);\n  padding-top: 20px;\n  padding-bottom: 30px;\n}\n\n/* .dashboard .dashboard-row { */\n  /* margin-bottom: 30px; */\n/* } */\n\n.dashboard tbody th {\n  font-weight: 100;\n}\n\n.scan-id-link {\n  text-decoration: none;\n  color: black;\n  border-bottom: 1px black dotted;\n}\n\n.scan-id-link:hover {\n  text-decoration: none;\n  color: black;\n  border-bottom: 1px black solid;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
