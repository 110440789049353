import React, {useEffect, useState} from "react";
import {Button, Dropdown, Card, Row, Container} from "react-bootstrap";
import {getOrganizationsAdmin, getBillingManagementRedirectURL} from "../utils/apiRequests";
import {useAuth} from "@clerk/clerk-react";

function SettingsBilling() {
  const [organizations, setOrganizations] = useState([])
  const [selectedOrganizationName, setSelectedOrganizationName] = useState('Select an Organization')
  const [selectedOrganizationId, setSelectedOrganizationId] = useState('')
  const { getToken } = useAuth()

  /**
   * setSelectedOrg
   * @param org
   * @returns {Promise<void>}
   */
  async function setSelectedOrg(org) {
    setSelectedOrganizationName(org.name)
    setSelectedOrganizationId(org.id)
  }

  /**
   * callBillingManagementRedirectURL
   * @returns {Promise<void>}
   */
  async function callBillingManagementRedirectURL() {
    let token = await getToken({ template: 'protean-default' });
    const { data, error } = await getBillingManagementRedirectURL(token, selectedOrganizationId);

    if (error != null) {
      // console.error(error)
    }

    if (data && data.hasOwnProperty("redirect_url")) {
      window.open(data.redirect_url, '_blank')
    }
  }

  /**
   * getOrganizationsFromApi
   * @returns {Promise<void>}
   */
  async function getOrganizationsFromApi() {
    let token = await getToken({ template: 'protean-default' });
    const { data, error } = await getOrganizationsAdmin(token);

    if (error != null) {
      // console.log("There was an error during getOrganizationsFromApi")
      // console.error(error)
    } else {
      // console.log("getOrganizationsAdmin result: " + JSON.stringify(data))
      setOrganizations([...data])
    }
  }

  useEffect(() => {
    getOrganizationsFromApi()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const OrganizationSelectorDropdownItems = () => {
    return (
      <>
      <Dropdown>
        <Dropdown.Toggle
          variant="outline-dark"
          id="dropdown-basic"
          className="rounded-1"
        >
            { selectedOrganizationName }
          </Dropdown.Toggle>
          <Dropdown.Menu>
            { organizations.map(org =>
              <Dropdown.Item
                eventKey={org.name}
                key={org.name}
                onClick={() => setSelectedOrg(org)}
              >
                {org.name}
                </Dropdown.Item>
            ) }
          </Dropdown.Menu>
        </Dropdown>

        {
      selectedOrganizationId === '' ? <div /> :
          <Button
              variant="outline-dark"
              style={{
                marginTop: '10px'
              }}
              onClick={
                callBillingManagementRedirectURL
              }
          >
            Manage Subscription
          </Button>
        }
      </>
    )
  }

  return (
    <Container>
      <h3>Billing</h3>

      <Row>

        <Card style={{ width: '20rem', height: '20rem' }}>
          <Card.Body>
            <Card.Title>View Plans</Card.Title>
            <Card.Text>
              To see our available plans check out our Pricing page!
            </Card.Text>
            <Button variant="outline-dark" href="/pricing">Pricing</Button>
          </Card.Body>
        </Card>

        <br />

        <Card style={{ width: '20rem', height: '20rem' }}>
          <Card.Body>
            <Card.Title>
              Manage Plans
            </Card.Title>
            <Card.Text>
              To manage your plan please choose which organization you'd like to manage
            </Card.Text>

            <OrganizationSelectorDropdownItems />
          </Card.Body>
        </Card>

      </Row>

    </Container>
  )
}

export default SettingsBilling