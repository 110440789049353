import React from "react";
import logo from '../images/protean-labs-logo.png';

function ProteanLabsLogo({size='70'}) {
  return (
    <img
      src={logo}
      alt="https://protean-labs.io"
      width={size}
      height={size}
      style={{pointerEvents: 'none'}}
    />
  )
}

export default ProteanLabsLogo;