import React, { useState, useEffect } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const APIErrorModal = ({ message, show, onClose }) => {
  const [showToast, setShowToast] = useState(show);

  useEffect(() => {
    setShowToast(show);
    if (show) {
      const timer = setTimeout(() => {
        setShowToast(false);
        onClose();
      }, 10_000); // Display for 10 seconds
      return () => clearTimeout(timer);
    }
  }, [show, onClose]);

  return (
    <ToastContainer position="top-end" className="p-3" style={{ zIndex: 9999 }}>
     <Toast
       show={showToast}
       onClose={() => {
         setShowToast(false);
         onClose();
       }}
     >
       <Toast.Header>
         <strong className="me-auto">Error</strong>
       </Toast.Header>
       <Toast.Body>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default APIErrorModal;
