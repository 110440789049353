import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCodeBranch } from "@fortawesome/free-solid-svg-icons";

import './NavBarDropdownButton.css';

const NavBarDropdownButton = ({ name }) => {
  /* Based on socket.dev's dropdown menu */
  return (
    <div className='mainContainer'>

      <div className='iconContainer'>
        <FontAwesomeIcon className='iconContainerIcon' icon={faCodeBranch} />
      </div>

      <span className='textContainer'>
        {name}
      </span>
    </div>
  );
}

export default NavBarDropdownButton;
