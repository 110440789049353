import {useAuth} from "@clerk/clerk-react";
import {useEffect, useState} from "react";
import {
  Button,
  Form,
  Modal
} from "react-bootstrap"
import {createProject, getOrganizations} from "../utils/apiRequests"
import APIErrorModal from "./APIErrorModal";

function getAvailableEcosystems() {
  return [
    'Python',
    'Javascript'
  ]
}

function NewProjectsModal(props) {
  const [projectName, setProjectName] = useState('')
  const [selectedOrganization, setSelectedOrganization] = useState('')
  const [ecosystem, setEcosystem] = useState('python')
  const [organizations, setOrganizations] = useState([])

  const [errorModalShow, setErrorModalShow] = useState(false)
  const [errorModalMsg, setErrorModalMsg] = useState('')

  const {getToken} = useAuth()

  // Get list of Organizations for the dropdown
  useEffect(() => {
    (async () => {
      const token = await getToken()
      const newOrgs = await getOrganizations(token)

      if (newOrgs.data !== null) {
        let organizationNamesAndIds = []
        newOrgs.data.forEach((orgInfo) => {
          let n = orgInfo.name + ' - ' + orgInfo.id
          organizationNamesAndIds.push(n)
        });

        await setOrganizations(organizationNamesAndIds)
        await setSelectedOrganization(organizationNamesAndIds[0])
      }
    })()
    // eslint-disable-next-line
  }, [])

  // Form submit button handler
  async function formSubmit() {
    const token = await getToken({template: "protean-default"})
    // yeah its hacky, we'll do it better later
    const organizationNameAndIdSplit = selectedOrganization.split(' - ')
    if (organizationNameAndIdSplit.length < 2) {
      return
    }

    let id = organizationNameAndIdSplit[1]
    
    const formData = {
      name: projectName,
      organization_id: id,
      ecosystem: ecosystem,
    }

    console.log(`formData: ${formData}`)

    // eslint-disable-next-line
    const {data, error} = await createProject(token, formData)
    if (error != null) {
      // Modal
      console.log(error)
      setErrorModalMsg(error.message || 'Your request could not be processed')
      setErrorModalShow(true)
    } else {
      props.onHide()
    }
    // setSubmitted(false)
  }

  return (
    <>
      <APIErrorModal
        message={errorModalMsg}
        show={errorModalShow}
        onClose={() => setErrorModalShow(false)}
      />

      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onExit={() => {} }
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Create a new Project
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Users service"
                onChange={v => setProjectName(v.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Organization</Form.Label>
              <Form.Select
                onChange={v =>
                  setSelectedOrganization(v.target.value)
                }
              >
                { organizations !== null ? organizations.map(org => {
                    return (<option key={org} value={org}>{org}</option>)
                  })
                :
                  <option key='nil' value='Please create an Organization first' disabled>
                    Please create an organization first
                  </option>
                }
              </Form.Select>
            </Form.Group>

            <Form.Group>
              <Form.Label>Ecosystem</Form.Label>
              <Form.Select
                  onChange={v => setEcosystem(v.target.value) }
                  // onChange={v => setEcosystem(v) }
              >
                { getAvailableEcosystems().map(i => {
                  return (
                      <option key={i} value={i}>{i}</option>
                  )
                })
                }
              </Form.Select>
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
              onClick={ formSubmit }
              disabled={projectName.length < 5}
              type="submit"
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default NewProjectsModal