import React from 'react';

const DateTimeFormatter = ({ dateTimeString }) => {
  const formatDate = (dateString) => {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${month} ${day}, ${year} at ${hours}:${minutes}`;
  };

  return (
    <div style={{
      fontSize: '14px',
      fontWeight: 'normal'
    }}>
      {formatDate(dateTimeString)}
    </div>
  );
};

export default DateTimeFormatter;
