import { useEffect, useState } from 'react';
import { useAuth } from '@clerk/clerk-react';
import {
  getOrganizationInvites,
  acceptOrganizationInvite,
  declineOrganizationInvite
} from '../utils/apiRequests';
import { Container, Row, Table, Button } from 'react-bootstrap'
import DateTimeFormatter from "./DateTimeFormatter";

import "./SettingsInvitations.css"


function SettingsInvitations() {
  const { getToken } = useAuth()
  const [invites, setInvites] = useState([])

  /**
   *
   */
  async function getInviteData() {
    let token = await getToken({ template: 'protean-default' });

    const { data, error } = await getOrganizationInvites(token)

    if (error != null) {
      // TODO: need better error handling
      // console.log('[ERROR] Message: ')
      // console.error(error)
    } else {
      setInvites(data)
    }
  }

  //
  useEffect(() => {
    getInviteData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   *
   */
  async function removeInvite(inviteId) {
    if (invites.length < 1) {
      return
    }

    let tmpInvites = []
    invites.forEach(invite => {
      if (invite.id !== inviteId) {
        tmpInvites.push(invite)
      }
    });

    setInvites(tmpInvites);
  }

  /**
   *
   */
  async function handleAcceptClick(inviteId) {
    let token = await getToken({ template: 'protean-default' });
    let res = await acceptOrganizationInvite(token, inviteId);
    if (! res.error) {
      removeInvite(inviteId)
    }
  }

  /**
   *
   */
  async function handleDeclineClick(inviteId) {
    let token = await getToken({ template: 'protean-default' });
    let res = await declineOrganizationInvite(token, inviteId);
    if (! res.error) {
      removeInvite(inviteId)
    }
  }

  /**
   *
   */
  return (
    <Container className='settings-invites'>

      <Row>
        <h3>Invites</h3>
      </Row>

      <Row>
        <Table className="invitations-table">
          <thead>
          <tr>
            <th>Name</th>
            <th>ID</th>
            <th>Created</th>
            <th></th>
          </tr>
          </thead>

          <tbody>
          { invites !== null && invites.length > 0 ? invites.map(invite => {
                return (
                    <tr key={invite.id}>
                      <td>{invite.name}</td>
                      <td><pre>{invite.id}</pre></td>
                      <td><pre><DateTimeFormatter dateTimeString={invite.created_at} /></pre></td>
                      <td>
                        <Button
                          variant='success'
                          size="sm"
                          style={{marginRight: '5px'}}
                          onClick={() => handleAcceptClick(invite.id)}
                        >
                          Accept
                        </Button>

                        <Button
                          variant='danger'
                          size="sm"
                          onClick={() => handleDeclineClick(invite.id)}
                        >
                          Decline
                        </Button>
                      </td>
                    </tr>
                )
              })
              :
              <tr>
                <td>
                  <p>No invites</p>
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
          }
          </tbody>
        </Table>
      </Row>

    </Container>
  )
}

export default SettingsInvitations