import React from "react";
import { Col, Container, Row, ListGroup } from "react-bootstrap";
import { Outlet, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuildingLock,
  faBuildingUser,
  faFingerprint,
  faCodeBranch,
  faMoneyBill,
  faCodeMerge,
} from "@fortawesome/free-solid-svg-icons";
import "./Dashboard.css";
import { LinkContainer } from "react-router-bootstrap";

function DashboardPage() {
  const { pathname } = useLocation();

  const dashboardTabs = [
    {
      text: "Scans",
      path: "/dashboard/scans",
      icon: faCodeMerge,
    },
  ];

  const settingsTabs = [
    {
      text: "Organizations",
      path: "/dashboard/settings/organizations",
      icon: faBuildingUser,
    },
    {
      text: "Invitations",
      path: "/dashboard/settings/invitations",
      icon: faBuildingLock,
    },
    {
      text: "Projects",
      path: "/dashboard/settings/projects",
      icon: faCodeBranch
    },
    {
      text: "Billing",
      path: "/dashboard/settings/billing",
      icon: faMoneyBill
    },
  ];

  const developersTabs = [
    {
      text: "API Keys",
      path: "/dashboard/settings/api-keys",
      icon: faFingerprint
    },
  ];

  return (
    <Container fluid
      style={{
        paddingLeft: "0px",
        marginLeft: "0px",
        paddingRight: "0px",
        marginRight: "0px",
      }}
    >
      <Row style={{flexWrap: 'nowrap', marginRight: '0'}}>
        <Col
          md={2}
          sm="true"
          style={{
            minHeight: '80vh',
            paddingTop: '10px',
            borderRight: '1px solid lightGray',
            width: '250px',
            fontSize: '0.8rem',
          }}
        >
          <ListGroup variant="flush">
            {/* Dashboard buttons */}
            <ListGroup.Item style={{ fontWeight: 'bold' }}>Console</ListGroup.Item>
            {dashboardTabs.map((i) => {
              const { text, path, icon } = i;
              return (
                <LinkContainer to={path} key={text}>
                  <ListGroup.Item action active={pathname.indexOf(path) > -1}>
                    <FontAwesomeIcon className="settingsIcon" icon={icon} />
                    {text}
                  </ListGroup.Item>
                </LinkContainer>
              );
            })}

            {/* Settings buttons */}
            <ListGroup.Item style={{ paddingTop: '20px', fontWeight: 'bold' }}>Settings</ListGroup.Item>
            {settingsTabs.map((i) => {
              const { text, path, icon } = i;
              return (
                <LinkContainer to={path} key={text}>
                  <ListGroup.Item action active={pathname.indexOf(path) > -1}>
                    <FontAwesomeIcon className="settingsIcon" icon={icon} />
                    {text}
                  </ListGroup.Item>
                </LinkContainer>
              );
            })}

            {/* Developers buttons */}
            <ListGroup.Item style={{ paddingTop: '20px', fontWeight: 'bold' }}>Developers</ListGroup.Item>
            {developersTabs.map((i) => {
              const { text, path, icon } = i;
              return (
                <LinkContainer to={path} key={text}>
                  <ListGroup.Item action active={pathname.indexOf(path) > -1}>
                    <FontAwesomeIcon className="settingsIcon" icon={icon} />
                    {text}
                  </ListGroup.Item>
                </LinkContainer>
              );
            })}

          </ListGroup>
        </Col>

        <Col fluid="auto"
          style={{
            paddingTop: '10px',
            paddingBottom: '10px',
            backgroundColor: '#f2f2f2',
            marginRight: '0px',
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              padding: '15px',
              border: '1px solid lightGray',
            }}
          >
            <Outlet />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default DashboardPage;
