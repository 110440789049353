import { useAuth } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Modal
} from "react-bootstrap"
import { createProject, createOrganization, getOrganizations } from "../utils/apiRequests"
import APIErrorModal from "./APIErrorModal";

// Form Organization select item for formData organization_id
function OrganizationFormItem({ formData, setFormData }) {
  const [orgs, setOrgs] = useState([])
  const { getToken } = useAuth()

  useEffect(() => {
    (async () => {
      const token = await getToken()
      const newOrgs = await getOrganizations(token)
      setOrgs(newOrgs.data)
      // set default organization_id to first org
      setFormData({ ...formData, organization_id: newOrgs.data[0].organization_id })
    })()
    // eslint-disable-next-line
  }, [])

  return (
    <Form.Group>
      <Form.Label>Organization</Form.Label>
      <Form.Select
        onChange={v =>
          setFormData({ ...formData, organization_id: v.target.value })
        }
      >
        { orgs ? orgs.map(i => {
          return (
          <option key={i.id} value={i.organization_id}>{i.name}</option>
          )
        })
            :
            <option key='nil' value='Please create an Organization first' disabled>
              Please create an organization first
            </option>
        }
      </Form.Select>
    </Form.Group>
  )
}

function NewSettingsModal(props) {
  const { type } = props
  let emptyFormData = {
    name: ""
  }
  const [formData, setFormData] = useState(emptyFormData)
  const [submitted, setSubmitted] = useState(false)

  const [errorModalShow, setErrorModalShow] = useState(false)
  const [errorModalMsg, setErrorModalMsg] = useState('')

  const { getToken } = useAuth()

  async function formSubmit() {
    setSubmitted(true)
    const token = await getToken({ template: "protean-default" })
    let req
    switch (props.type) {
      case "project":
        req = createProject
        break;
      case "organization":
        req = createOrganization
        break;
      default:
        return
    }
    // Not sure why we're not using 'data' here but i need to shut the linter up
    // eslint-disable-next-line
    const { data, error } = await req(token, formData)
    if (error != null) {
      console.error(error)

      // Modal
      setErrorModalMsg(error.message || 'Your request could not be processed')
      setErrorModalShow(true)
    } else {
      props.onHide()
    }
    setSubmitted(false)
  }

  return (
    <>
      <APIErrorModal
        message={errorModalMsg}
        show={errorModalShow}
        onClose={() => setErrorModalShow(false)}
      />

    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onExit={() => {
        setFormData(emptyFormData)
        setSubmitted(false)
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Create a new {type[0].toUpperCase() + type.substring(1)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Name"
              onChange={i => setFormData({ ...formData, name: i.target.value })}
            />
          </Form.Group>
          {type === "project" &&
            <>
              <br />
              <OrganizationFormItem setFormData={setFormData} formData={formData} />
            </>
          }
          <br />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={submitted || Object.values(formData).some(i => i === "")}
          onClick={formSubmit}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  )
}

export default NewSettingsModal