// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.protean-navbar {
  box-shadow: 0 2px 30px rgba(0,0,0,.08)
}

.dropdown-menu.active {
  --bs-dropdown-link-active-bg: white;
}

.nav-icon {
  vertical-align: middle;
  height: 20px;
  padding: 0;
}

#collapsible-nav-dropdown {
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/components/NavBar.css"],"names":[],"mappings":"AAAA;EACE;AACF;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,YAAY;AACd","sourcesContent":[".protean-navbar {\n  box-shadow: 0 2px 30px rgba(0,0,0,.08)\n}\n\n.dropdown-menu.active {\n  --bs-dropdown-link-active-bg: white;\n}\n\n.nav-icon {\n  vertical-align: middle;\n  height: 20px;\n  padding: 0;\n}\n\n#collapsible-nav-dropdown {\n  color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
