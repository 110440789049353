import React from "react";
import { Container, Row, Stack } from "react-bootstrap";

function Careers() {
  return (
    <>
    <div
      style={{
        width: '100%',
        minHeight: "90vh",
        backgroundColor: '#d0c5f2',
      }}
    >
      <Container className="hero"
          style={{
            minHeight: "90vh",
            paddingTop: "20px",
            paddingLeft: "auto",
            paddingRight: "auto",
          }}
      >
        <Row>
          <h2 style={{
            width: 'auto',
            margin: 'auto',
            paddingTop: '70px',
            paddingLeft: '60px',
            paddingRight: '60px',
            paddingBottom: '70px',
            marginTop: '20px',
            marginBottom: '2em',
            border: '1px solid white',
            borderRadius: '20px',
            backgroundColor: '#d0c5f2'
          }}>
            Make the internet a safer place with us
          </h2>
        </Row>
        </Container>
      </div>

      <Container>
        <center>
        <Row className="hero-row1 justify-content-md-center"
        style={{
          maxWidth: '55em',
          paddingTop: '5em',
          paddingBottom: '5em',
          marginBottom: '5em',
          border: '2px solid black',
          borderRadius: '20px'
        }}
        >
            <Stack gap={3}>
              <h3 style={{fontSize: '30px'}}>Come join the team</h3>
              <h4 style={{fontSize: '20px'}}>Whether you're a Hacker, an Artist, or just plain passionate about what you do we'd love to hear from you.</h4>
            </Stack>
        </Row>

        <Row className="hero-row1 justify-content-md-center"
        style={{
          maxWidth: '55em',
          paddingTop: '5em',
          paddingBottom: '5em',
          marginBottom: '5em',
          border: '2px solid black',
          borderRadius: '20px'
        }}
        >
            <Stack gap={3}>
              <h3 style={{fontSize: '30px'}}>Remote first culture</h3>
              <h4 style={{fontSize: '20px'}}>Founded in Raleigh, North Carolina in 2022 we are a diverse team located all over the United States!</h4>
            </Stack>
        </Row>

        <Row className="hero-row1 justify-content-md-center"
        style={{
          maxWidth: '55em',
          paddingTop: '5em',
          paddingBottom: '5em',
          marginBottom: '5em',
          border: '2px solid black',
          borderRadius: '20px'
        }}
        >
            <Stack gap={3}>
              <h3 style={{fontSize: '30px'}}>Who we're looking for</h3>
                <h5><b>AI</b></h5>
                <span>Director of AI</span>
                <span>Principal AI Engineer</span>

                <h5><b>Engineering</b></h5>
                <span>Senior Backend Engineer</span>
                <span>Senior DevOps Engineer</span>
                <span>Senior Frontend Engineer</span>
                <span>Technical Support Engineer</span>

                <h5><b>Customer Success</b></h5>
                <span>Director of Customer Success</span>

                <h5><b>Finance</b></h5>
                <span>Senior Accountant</span>

                <h5><b>Marketing</b></h5>
                <span>Inbound Sales Development Manager</span>
                <span>Director of Marketing</span>
            </Stack>
        </Row>

        <Row className="hero-row1 justify-content-md-center"
        style={{
          maxWidth: '55em',
          paddingTop: '5em',
          paddingBottom: '5em',
          marginBottom: '5em',
          border: '2px solid black',
          borderRadius: '20px'
        }}
        >
            <Stack gap={3}>
              <h3 style={{fontSize: '30px'}}>How it works</h3>
              <h4 style={{fontSize: '20px'}}>blah</h4>
            </Stack>
        </Row>

        </center>

      </Container>
    </>
  )
}

export default Careers;
