// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `li.colHeader {
    font-size: 18px;
    color: #c897ff;
}

li.colLink {
    margin-top: 8px;
}

li.colLink a {
    font-size: 18px;
    color: #ffffff;
    text-decoration: none;
}

.colLink a:hover {
    -webkit-text-decoration: underline solid #ffffff;
            text-decoration: underline solid #ffffff;
}

footer {
    padding-top: 25px;
    background: black;
    color: white;
    position: relative;
    list-style-type: none;
}

#social-icon-row {
    margin-top: 20px;
}

#social-icon-row a {
    width: 36px;
    height: 36px;
    margin-right: 10px;
}

.social-icon {
    fill: rgb(198, 198, 198);
    width: 36px;
    height: 36px;
}

.social-icon:hover {
    fill: rgb(239, 239, 239);
}

.status-page-link-container {
    margin-top: 20px;
    width: 200px;
}

.status-page-link {
    font-size: 12px;
    color: rgb(159, 251, 168);
    text-decoration: none;
}

.status-page-link:hover {
    color: rgb(54, 203, 69);
}`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,gDAAwC;YAAxC,wCAAwC;AAC5C;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,wBAAwB;IACxB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":["li.colHeader {\n    font-size: 18px;\n    color: #c897ff;\n}\n\nli.colLink {\n    margin-top: 8px;\n}\n\nli.colLink a {\n    font-size: 18px;\n    color: #ffffff;\n    text-decoration: none;\n}\n\n.colLink a:hover {\n    text-decoration: underline solid #ffffff;\n}\n\nfooter {\n    padding-top: 25px;\n    background: black;\n    color: white;\n    position: relative;\n    list-style-type: none;\n}\n\n#social-icon-row {\n    margin-top: 20px;\n}\n\n#social-icon-row a {\n    width: 36px;\n    height: 36px;\n    margin-right: 10px;\n}\n\n.social-icon {\n    fill: rgb(198, 198, 198);\n    width: 36px;\n    height: 36px;\n}\n\n.social-icon:hover {\n    fill: rgb(239, 239, 239);\n}\n\n.status-page-link-container {\n    margin-top: 20px;\n    width: 200px;\n}\n\n.status-page-link {\n    font-size: 12px;\n    color: rgb(159, 251, 168);\n    text-decoration: none;\n}\n\n.status-page-link:hover {\n    color: rgb(54, 203, 69);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
