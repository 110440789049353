import React from 'react';
import CopyToClipboardButton from './CopyToClipboardButton';

const ApiKeyTruncated = (props) => {
  const { apiKey } = props;

  return (
    <pre>
      {apiKey.slice(0,8)}••••••••{apiKey.slice(-4)} <CopyToClipboardButton data={apiKey} />
    </pre>
  );
};

export default ApiKeyTruncated;

