// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settingsIcon {
  padding-right: 10px;
}

.list-group-item {
  border: 0;
}

.list-group-item.active {
  color: black;
  background-color: #ffffff;
  border-left: 3px solid #9a5df5;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,8BAA8B;AAChC","sourcesContent":[".settingsIcon {\n  padding-right: 10px;\n}\n\n.list-group-item {\n  border: 0;\n}\n\n.list-group-item.active {\n  color: black;\n  background-color: #ffffff;\n  border-left: 3px solid #9a5df5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
