import { Container } from "react-bootstrap"
import SVGSummerBeach from "../components/SVGSummerBeach";

function NotFound () {
  return (
    <Container
      style={{
        minHeight: '100vh',
        textAlign: 'center',
      }}
    >
      <h1
          style={{
            paddingTop: '50px',
            paddingBottom: '50px',
          }}
      >
        This page must be on vacation!
      </h1>
      <a href='/'>
        <SVGSummerBeach />
      </a>
    </Container>
  )
}

export default NotFound