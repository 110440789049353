import React from "react";

function PrivacyPolicy() {
  return (
    <div
      style={{
        textAlign: "left",
        paddingTop: "100px",
        paddingBottom: "100px",
        paddingLeft: "20%",
        paddingRight: "20%",
      }}
    >
      <h1>Security and Compliance</h1>
      <sub>
        Last updated: <b>Feb 2024</b>
      </sub>
      <br />
      <br />
      <br />

      <h2>
        <b>SOC 2 Type 2</b>
      </h2>
      <p>
        SOC 2 Type 2 (
        <a
          href="https://www.aicpa-cima.com/topic/audit-assurance/audit-and-assurance-greater-than-soc-2"
          target="_blank" rel="noreferrer"
        >
          SOC 2
        </a>
        ) is a report based on the Trust Services Criteria (
        <a
          href="https://www.aicpa-cima.com/resources/download/2017-trust-services-criteria-with-revised-points-of-focus-2022"
          target="_blank" rel="noreferrer"
        >
          TSC
        </a>
        ) and provides an in-depth review of an organization's controls over
        time. It evaluates the effectiveness of these controls in addressing
        security, availability, processing integrity, confidentiality, and
        privacy of a system. At Protean Labs, we adhere to all the guidelines of
        SOC 2 Type 2 to ensure the security and privacy of our systems and data.
      </p>
      <p>
        We are currently in the process of obtaining our attestation and will
        update here upon completion.
      </p>
      <br />

      <h2>
        <b>ISO 27001</b>
      </h2>
      <p>
        ISO/IEC 27001 (
        <a href="https://www.iso.org/standard/27001" target="_blank" rel="noreferrer">
          ISO 27001
        </a>
        ) is the leading international standard for information security,
        created to help organizations of any size or industry protect their
        information through the adoption of an Information Security Management
        System (ISMS). It provides a framework and guidelines for establishing,
        implementing, and managing an ISMS, requiring organizations to identify
        information security risks and select appropriate controls to address
        them.
      </p>
      <p>
        We are currently in the process of obtaining our certificate and will
        update here upon completion.
      </p>
      <br />

      <h2>
        <b>PCI DSS</b>
      </h2>
      <p>
        Payment Card Industry Data Security Standard (
        <a
          href="https://www.pcisecuritystandards.org/merchants/#resources"
          target="_blank" rel="noreferrer"
        >
          PCI DSS
        </a>
        ) defines the security requirements for any entity handling credit card
        information. PCI compliance covers all of transmission, storage, and
        processing of credit or payment information. Compliance with PCI DSS is
        not mandated by law but is enforced through contractual obligations with
        payment card companies, and non-compliance can result in fines and other
        penalties.
      </p>
      <p>
        Protean Labs uses Stripe for all our payment processing and never
        handles your payment information.
      </p>
      <br />

      <h2>
        <b>GDPR</b>
      </h2>
      <p>
        The General Data Protection Regulation (
        <a href="https://gdpr-info.eu/" target="_blank" rel="noreferrer">
          GDPR
        </a>
        ) is a legal framework that sets guidelines for the collection and
        processing of personal information of individuals within the European
        Union (EU).
      </p>

      <p>
        We are committed to GDPR compliance through:
        <ul>
          <li>Ensuring transparent data processing practices.</li>
          <li>Obtaining explicit consent before processing personal data.</li>
          <li>
            Implementing robust security measures to protect personal
            information.
          </li>
          <li>
            Providing individuals with the right to access, rectify, and erase
            their data upon request.
          </li>
          <li>
            Conducting regular audits to ensure ongoing compliance with GDPR
            regulations.
          </li>
        </ul>
      </p>
      <br />
    </div>
  );
}

export default PrivacyPolicy;
