// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-new-button {
  float: right;
}

.org-name-link {
  text-decoration: none;
  color: black;
  border-bottom: 1px black dotted;
}

.org-name-link:hover {
  text-decoration: none;
  color: black;
  border-bottom: 1px black solid;
}

.organizations-table tr td {
  font-weight: normal;
  padding-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/SettingsOrganizations.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,+BAA+B;AACjC;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".create-new-button {\n  float: right;\n}\n\n.org-name-link {\n  text-decoration: none;\n  color: black;\n  border-bottom: 1px black dotted;\n}\n\n.org-name-link:hover {\n  text-decoration: none;\n  color: black;\n  border-bottom: 1px black solid;\n}\n\n.organizations-table tr td {\n  font-weight: normal;\n  padding-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
