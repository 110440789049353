// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#root-page {
  max-width: 960px;
  text-align: center;
}

#root-page h1 {
  font-weight: bold;
}

#root-page .hero {
  padding-top: 60px;
}

#root-page .hero-row1 {
  margin-top: 40px;
  margin-bottom: 40px;
}

#hero-text-row {
  font-size: 65px;
  background-image: linear-gradient(40deg, #9a5df5, #000);
  background-clip: text;
  color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/pages/Root.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,uDAAuD;EACvD,qBAAqB;EACrB,kBAAkB;AACpB","sourcesContent":["#root-page {\n  max-width: 960px;\n  text-align: center;\n}\n\n#root-page h1 {\n  font-weight: bold;\n}\n\n#root-page .hero {\n  padding-top: 60px;\n}\n\n#root-page .hero-row1 {\n  margin-top: 40px;\n  margin-bottom: 40px;\n}\n\n#hero-text-row {\n  font-size: 65px;\n  background-image: linear-gradient(40deg, #9a5df5, #000);\n  background-clip: text;\n  color: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
