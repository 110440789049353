import React from "react";
import {
  Stack,
  Container,
  Col,
  Card,
  Row,
  Image,
  Button
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarcode,
  faCodeCompare,
  faLaptopCode
} from "@fortawesome/free-solid-svg-icons";

import protean_cli_carbon from '../images/protean_cli_carbon.png';
import production_isometric from '../images/production-isometric.png';
import information_flow from '../images/information-flow-isometric.png';
import team_success from '../images/team-success-isometric.png';
import data_process from '../images/data-process-isometric.png';

import "./Root.css";
import { SignUpButton } from "@clerk/clerk-react";

function RootPage() {
  return (
    <Container id="root-page">

      <Container className="hero"
       style={{
         minHeight: '95vh',
       }}
      >
        <Row className="justify-content-md-center">
          <Col>
            <Stack gap={3}>
              <h1 id="hero-text-row">
                Never release with a vulnerable dependency again
              </h1>
            </Stack>
          </Col>

          <Image
            src={protean_cli_carbon}
            alt="Protean Labs CLI image of a terminal output"
            style={{
              pointerEvents: 'none',
            }}
          />
        </Row>
      </Container>

      <Container style={{marginTop: '20px'}}>
        <Row>
          <Col>
            <h4 style={{
              paddingTop: '110px'
            }}>
              <code style={{
                  color: 'black',
                  fontSize: '40px'
                }}>
                <b>
                  Simplify the compliance process
                </b>
              </code>

              <br />
              <br />

              <code style={{color: 'black', fontSize: '20px'}}>
               Our Software Composition Analysis (SCA) service helps you stay compliant with industry regulations by identifying and reporting outdated or vulnerable third-party packages that may pose security risks
              </code>
            </h4>
          </Col>
          <Col>
            <Image
              src={information_flow}
              alt="A picture of a woman imagining the flow of information"
              style={{
                maxWidth: '100%',
                minWidth: '200px',
                height: 'auto',
                pointerEvents: 'none',
                marginTop: '50px',
              }}
            />
          </Col>
        </Row>
      </Container>

      <Container style={{marginTop: '120px'}}>
        <Row>
          <Col>
            <Image
              src={production_isometric}
              alt="A picture of a woman scanning boxes"
              style={{
                maxWidth: '100%',
                minWidth: '200px',
                height: 'auto',
                pointerEvents: 'none',
                marginTop: '50px',
              }}
            />
          </Col>

          <Col>
            <h4 style={{
              paddingTop: '110px'
            }}>
              <code style={{
                  color: 'black',
                  fontSize: '40px'
                }}>
                <b>
                  Catch problems before they hit Prod
                </b>
              </code>

              <br />
              <br />

              <code style={{color: 'black', fontSize: '20px'}}>
                Early detection and remediation can prevent security breaches that could lead to a compromised system or sensitive data leak
              </code>
            </h4>
          </Col>
        </Row>
      </Container>

      <Container style={{marginTop: '120px'}}>
        <Row>
          <Col>
            <h4 style={{
              paddingTop: '110px'
            }}>
              <code style={{
                  color: 'black',
                  fontSize: '40px'
                }}>
                <b>
                  Supercharge your DevSecOps
                </b>
              </code>

              <br />
              <br />

              <code style={{color: 'black', fontSize: '20px'}}>
                96% of Engineers surveyed said their organization would benefit from the automation of security and compliance processes
              </code>
            </h4>
          </Col>
          <Col>
            <Image
              src={team_success}
              alt="A picture of a team of people celebrating"
              style={{
                maxWidth: '100%',
                minWidth: '200px',
                height: 'auto',
                pointerEvents: 'none',
                marginTop: '50px',
              }}
            />
          </Col>
        </Row>
      </Container>

      <Container style={{marginTop: '120px'}}>
        <Row>
          <Col>
            <Image
              src={data_process}
              alt="A picture of a man placing a server on the floor next to a laptop"
              style={{
                maxWidth: '100%',
                minWidth: '200px',
                height: 'auto',
                pointerEvents: 'none',
                marginTop: '50px',
              }}
            />
          </Col>

          <Col>
            <h4 style={{
              paddingTop: '110px'
            }}>
              <code style={{
                  color: 'black',
                  fontSize: '40px'
                }}>
                <b>
                  Seamlessly fits into your SDLC
                </b>
              </code>

              <br />
              <br />

              <code style={{color: 'black', fontSize: '20px'}}>
                Our CLI tool works no matter which CI/CD platform you use, making integration a breeze
              </code>
            </h4>
          </Col>
        </Row>
      </Container>

      <Container style={{ marginTop: '200px' }}>
        <Row>
          <Col>
            <h3>
              <code
                style={{
                  fontSize: '40px',
                  color: 'black',
                }}>
                The total global cost of cybercrime was $8,000,000,000,000+ dollars in 2023.
              </code>
            </h3>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row style={{
          marginTop: '140px',
          justifyContent: "center",
        }} sm={1}>
          <Col md="auto">
            <Card
              style={{
                border: '1',
                borderColor: 'black',
                backgroundColor: 'white',
                maxWidth: '250px',
                minHeight: '250px',
                fontSize: '22px',
                marginRight: 'auto',
                marginLeft: 'auto',
                marginTop: '25px',
              }}
            >
              <Card.Body>
                <Card.Title>
                  <FontAwesomeIcon icon={faBarcode} size="3x" />
                </Card.Title>
                <Card.Text>
                  Scan your project's open source dependencies in a matter of seconds
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md="auto">
            <Card
              style={{
                border: '1',
                borderColor: 'black',
                backgroundColor: 'white',
                maxWidth: '250px',
                minHeight: '250px',
                fontSize: '20px',
                marginRight: 'auto',
                marginLeft: 'auto',
                marginTop: '25px',
              }}
            >
              <Card.Body>
                <Card.Title>
                  <FontAwesomeIcon icon={faCodeCompare} size="3x" />
                </Card.Title>
                <Card.Text>
                  If we detect a vulnerable dependency in your SBOM then we halt your pipeline and alert you
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md="auto">
            <Card
              style={{
                border: '1',
                borderColor: 'black',
                backgroundColor: 'white',
                maxWidth: '250px',
                minHeight: '250px',
                fontSize: '22px',
                marginRight: 'auto',
                marginLeft: 'auto',
                marginTop: '25px',
              }}
            >
              <Card.Body>
                <Card.Title>
                  <FontAwesomeIcon icon={faLaptopCode} size="3x" />
                </Card.Title>
                <Card.Text>
                  Generate reports on a weekly basis, or on-demand
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row
        style={{
          marginBottom: '80px',
          marginTop: '120px'
        }}>
          <Col>
            <h3>
              <code
                style={{
                  color: 'black',
                  fontSize: '40px',
                }}>
                Data breaches cost companies over $5.6 Million dollars per breach on average
              </code>
            </h3>
          </Col>
        </Row>

        <Row>
          <SignUpButton>
            <Button
              style={{
                background: 'black',
                borderColor: 'black',
                maxWidth: '400px',
                paddingTop: '20px',
                paddingBottom: '20px',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: '80px',
              }}
            >
              Try out our Free tier →
            </Button>
          </SignUpButton>
        </Row>
      </Container>

    </Container>
  )
}

export default RootPage;
