// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (width <= 1250px) {
    #about-us-content-wrapper {
        padding: 10px;
    }
}

@media (width > 1250px) {
    #about-us-content-wrapper {
        padding: 100px;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/AboutUs.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":["@media (width <= 1250px) {\n    #about-us-content-wrapper {\n        padding: 10px;\n    }\n}\n\n@media (width > 1250px) {\n    #about-us-content-wrapper {\n        padding: 100px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
