import React from 'react';
import { ReactComponent as YourSvg } from '../images/summer_beach.svg';

const SVGPackageScan = () => {
  return(
      <YourSvg />
  );
}

export default SVGPackageScan ;
