import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import { Row, Card, Table } from "react-bootstrap";

import { getScan } from "../utils/apiRequests";

import "./DashboardScanDetail.css";

/**
 *
 */
function DashboardScanDetail() {
  const { scanId } = useParams();
  const { getToken } = useAuth();
  const [scanDetails, setScanDetails] = useState({});
  const [vulnCount, setVulnCount] = useState(0)
  const [affectedPackages, setAffectedPackages] = useState([]);

  /**
   *
   */
  async function getScanDetailsFromApi(scanId) {
    let token = await getToken({ template: "protean-default" });
    const { data, error } = await getScan(token, scanId);
    if (error != null) {
      // do something
    } else {
      setScanDetails(data);
      setAffectedPackages(data.result.affected);

      let c = 0;

      data.result.affected.forEach(pkg => {
        // count how many vulnerabilities we have across all our packages in this scan
        c += pkg.vulnerabilities.length
      });
      setVulnCount(c)
    }
  }

  /**
   * format CVE/CWE list
   */
  function formatCveList(cveList) {
    if (cveList.length < 1) {
      return ""
    }

    return cveList.join(', ')
  }

  // --
  useEffect(() => {
    getScanDetailsFromApi(scanId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --
  return (
    <Container>
      <h2>{scanDetails.project_name}</h2>
      <pre>{scanId}</pre>

      <Row style={{ paddingBottom: "4rem" }}>
        <Card
          style={{ minHeight: "17rem" }}
        >
          <Card.Body>
            <Card.Title>Affected Packages</Card.Title>

              <Table responsive hover className="members-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Version</th>
                    <th>CVE</th>
                    <th>Severity</th>
                    <th>Summary</th>
                  </tr>
                </thead>
                <tbody>
                {vulnCount > 0 ? (
                  affectedPackages.map((pkg) => (
                    pkg.vulnerabilities.map((vuln) => (
                      <tr key={pkg.id}>
                        <td key="name">{pkg.package}</td>
                        <td key="version">{pkg.version}</td>
                        <td key="cve">{formatCveList(vuln.CVEs)}</td>
                        <td key="severity">{vuln.severity}</td>
                        <td key="summary" style={{wordWrap: 'break-word'}}>{vuln.summary}</td>
                        {/* TODO: ignore button here */}
                      </tr>
                    ))
                  ))
                ) : (
                  <tr>
                    <td>No vulnerabilities</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
                </tbody>
              </Table>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
}

export default DashboardScanDetail;
