import React from "react";

/**
 * Severity must be one of:
 *   "critical",
 *   "high",
 *   "medium",
 *   "low"
 */
function DashboardTableNumber({severity, num}) {
  if (typeof(num) != "number") {
    num = Number(num);
  }
  const green = '#3be381'
  const red = '#f0655d'
  const orange = '#fe8421'
  const yellow = '#ffde00'
  const gray = '#929292'

  let numColor = green

  if (num > 0) {
    if (severity === "critical") {
        numColor = red
    }
    if (severity === "high") {
        numColor = orange
    }
    if (severity === "medium") {
        numColor = yellow
    }
    if (severity === "low") {
        numColor = gray
    }
  }

  return (
    <code style={{color: numColor}}>{num}</code>
  )
}

export default DashboardTableNumber;