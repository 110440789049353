import React, { useEffect, useState } from "react";
import { Spinner, Container, Row, Col, Table, Dropdown } from "react-bootstrap";
import "./DashboardScans.css";
import DashboardTableNumber from "../components/DashboardTableNumber";
import DateTimeFormatter from "../components/DateTimeFormatter";
import { getProjects, getScans } from "../utils/apiRequests";
import { useAuth } from "@clerk/clerk-react";

function DashboardScans() {
  const [statuses, setStatuses] = useState([]);
  const [projects, setProjects] = useState([]);
  const [scans, setScans] = useState([]);
  const [hasSelectedAProject, setHasSelectedAProject] = useState(false);
  const [selectedProjectName, setSelectedProjectName] = useState("Select Project");
  const { getToken } = useAuth();

  /**
   *
   */
  async function getProjectsFromApi() {
    let token = await getToken({ template: "protean-default" });
    const { data, error } = await getProjects(token);

    if (error != null) {
      // console.log("There was an error during getProjects")
      // console.error(error)
    } else {
      // console.log("getProjects result: " + JSON.stringify(data))
      setProjects([...data]);
    }
  }

  /**
   *
   */
  async function getScansFromApi(projectId) {
    let token = await getToken({ template: "protean-default" });
    const { data, error } = await getScans(token, projectId);

    if (error != null) {
      // console.log("There was an error during getScans")
      // console.error(error)
    }

    setScans(data);
    return data;
  }

  /**
   *
   */
  async function updateSelectedProject(project) {
    // console.log(`New project selected: name::[${project.projects_name}] id::[${project.projects_id}]`)
    setSelectedProjectName(project.projects_name);
    let newProjectScans = await getScansFromApi(project.projects_id);

    // console.log(`New project scans: ${newProjectScans}`)

    setHasSelectedAProject(true);
    setScans(newProjectScans);
  }

  //
  useEffect(() => {
    getProjectsFromApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //
  useEffect(() => {
    setStatuses(JSON.stringify(scans));
  }, [scans]);

  //
  useEffect(() => {}, [selectedProjectName]);

  const ProjectSelectorDropdownItems = () => {
    return (
      <Dropdown>
        <Dropdown.Toggle
          variant="outline-dark"
          id="dropdown-basic"
          className="rounded-1"
        >
          {selectedProjectName}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {
            projects.length > 0 ?
              projects.map((proj) => (
                <Dropdown.Item
                  eventKey={proj.projects_name}
                  key={proj.projects_name}
                  onClick={() => updateSelectedProject(proj)}
                >
                  {proj.projects_name}
                </Dropdown.Item>
              ))
              :
                <Dropdown.Item
                  key='NoProjects'
                  disabled={true}
                >
                  No Projects
                </Dropdown.Item>
          }
        </Dropdown.Menu>

      </Dropdown>
    );
  };

  return (
  <>
  { statuses ? (
    <>
      <Row className="dashboard-row">
        <Col>
          <h3>Scans</h3>
        </Col>
        <Col md="auto">
          <ProjectSelectorDropdownItems />
        </Col>
      </Row>

      <Row className="dashboard-row">
        <Container className="dashboard-scans-container">
          {
            scans && Array.isArray(scans) && scans.length > 0 ? (
              <Table hover className="status-table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Critical</th>
                    <th>High</th>
                    <th>Medium</th>
                    <th>Low</th>
                    <th>Created</th>
                  </tr>
                </thead>
                <tbody>
                {
                scans.map((scan) => (
                  <tr key={scan.id}>
                    <th key="id">
                      <a
                        className="scan-id-link"
                        href={"/dashboard/scans/" + scan.id}
                        style={{ fontWeight: 'normal' }} >
                          {scan.id}
                        </a>
                      </th>
                      <th key="critical_sev">
                        <DashboardTableNumber severity="critical" num={scan.result.status.critical ?? 0} />
                      </th>
                      <th key="high_sev">
                        <DashboardTableNumber severity="high" num={scan.result.status.high ?? 0} />
                      </th>
                      <th key="medium_sev">
                        <DashboardTableNumber severity="medium" num={scan.result.status.medium ?? 0} />
                      </th>
                      <th key="low_sev">
                        <DashboardTableNumber severity="low" num={scan.result.status.low ?? 0} />
                      </th>
                      <th key="created_at">
                      {
                        scan.created_at ?
                          <pre><DateTimeFormatter dateTimeString={scan.created_at} /></pre>
                        :
                          "-"
                      }
                      </th>
                  </tr>
                  ))
                }
                </tbody>
              </Table>
            ) : (
              hasSelectedAProject ? (
                // Only show the "No scans" message if the user has actually selected a project but we still have no vulns to display
                <div style={{
                  textAlign: 'center',
                }}>
                  <pre style={{fontSize: '16px'}}>This project has no scans yet, See our <a href="/docs">docs</a> to get started!</pre>
                </div>
              ) : (
                // If there are no vulns but the user still hasn't selected a project from the dropdown.
                <></>
              )
            )
          }
        </Container>
      </Row>
      </>
    ) : (
      <Container>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    )
  }</>
  )
}

export default DashboardScans;
